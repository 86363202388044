import React, { useEffect, useState } from "react";
import NavBar from "../../../components/Universal/NavBar";
import Header from "../../../components/Universal/Header";
import DynamicTable from "../../../components/Universal/DynamicTable";
import Elogo1trip from "../../../assets/images/Elogo1trip.png";
import Ebar1trip from "../../../assets/images/Ebar1trip.png";
import Elogo2 from "../../../assets/images/Elogo2.png";
import Ebar2 from "../../../assets/images/Ebar2.png";
import Elogo3 from "../../../assets/images/Elogo3.png";
import Ebar3 from "../../../assets/images/Ebar3.png";
import AnalyticsCard from "../../../components/Cards/AnalyticsCard";

import { useDispatch } from "react-redux";
import {
  readDriversLocationsAction,
  readTodayTripAction,
  readTripAction,
  readTripAnalyticsAction,
  readVehicleAction,
} from "../../../store/Service/ServiceAction";
import {
  customStyles,
  todayTripsHeaders,
  UNAVAILABLE,
} from "../../../constants/Constant";
import MapModel from "../../../components/Universal/MapModel";
import DateFilter from "../../../components/Universal/DateFilter";
import { useSocket } from "../../../components/Common/SocketContext";
import { useNavigate } from "react-router-dom";

const AnalyticsPage = ({ role }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useSocket();

  const [currentTripId, setCurrentTripId] = useState("");
  const [locationUpated, setLocationUpdated] = useState(true);
  const [showMapsModal, setShowMapsModal] = useState(false);

  const [driverLocationUpated, setDriverLocationUpdated] = useState(false);
  const [availableDriversDetail, setAvailableDriversDetail] = useState([]);
  const [currentId, setCurrentId] = useState("");
  const [tripData, setTripData] = useState([]);
  const [tripAnalyticsData, setTripAnalyticsData] = useState({
    emergencyTrips: 0,
    nonEmergencyTrips: 0,
    totalEarnings: 0,
  });
  const [currentTrip, setCurrentTrip] = useState({});
  const [filterRange, setFilterRange] = useState([
    {
      startDate: new Date(new Date().setHours(0, 0, 0)),
      endDate: new Date(new Date().setHours(23, 59, 59)),
      key: "selection",
    },
  ]);

  const [allVehicleData, setAllVehicleData] = useState([]);
  const [addOnDetailsList, setAddOnDetailsList] = useState([]);

  const containerStyle = {
    width: "100%",
    height: "500px",
  };

  // Check if there is data in the features' addonObjId arrays
  const checkFeaturesAvailable = (item) => {
    return (
      item.features &&
      item.features.some(
        (feature) => feature.addonObjId && feature.addonObjId.length > 0
      )
    );
  };

  useEffect(() => {
    dispatch(
      readVehicleAction({
        callback: (res) => {
          if (res?.type === 1) {
            setAllVehicleData(res?.data);
          }
        },
      })
    );
  }, []);

  useEffect(() => {
    if (role) {
      dispatch(
        readDriversLocationsAction({
          callback: (res) => {
            if (res?.type === 1) {
              const data = res?.data
                // ?.filter((i) => i?.coords)
                .map((item) => ({
                  ...item,
                  isOnline: false,
                  coords: item?.user?.lastLocation?.coords
                    ? item?.user?.lastLocation?.coords
                    : {
                        latitude: 17.5145267,
                        longitude: 78.3873372,
                        accuracy: 15.8,
                        speed: -1,
                        speed_accuracy: 1.5,
                        heading: -1,
                        heading_accuracy: 45,
                        altitude: 538,
                        ellipsoidal_altitude: 538,
                        altitude_accuracy: 2,
                        age: 2761,
                      },
                }));

              setAvailableDriversDetail(data);
            }
          },
        })
      );
    }
  }, [role]);

  useEffect(() => {
    if (currentTripId) {
      dispatch(
        readTripAction({
          apiPayloadRequest: {
            _id: currentTripId,
          },
          callback: (res) => {
            if (res.type === 1) {
              const data = res?.data[0];
              setCurrentTrip(data);
              let currentVehicleData = allVehicleData?.find(
                (v) => v?._id === data?.vehicleObjId?.[0]?._id
              );
              if (currentVehicleData) {
                let featuresList = checkFeaturesAvailable(currentVehicleData)
                  ? currentVehicleData?.features?.map((v) => v?.addonObjId[0])
                  : [];
                if (featuresList?.length > 0) {
                  if (data?.addons?.length > 0) {
                    featuresList = featuresList?.map((d) => {
                      const availableAddon = data?.addons?.find(
                        (v) => v?._id === d?._id
                      );
                      if (availableAddon) {
                        return {
                          ...d,
                          quantity: Number(availableAddon?.count ?? 0),
                        };
                      }
                      return d;
                    });
                    setAddOnDetailsList(featuresList);
                  }
                }
              }
            }
          },
        })
      );
    }
  }, [currentTripId]);

  const getDriverLastLocation = () => {
    if (
      currentTrip?.drivers?.length > 0 &&
      availableDriversDetail?.length > 0
    ) {
      const getLatestDriverDetails = availableDriversDetail?.filter(
        (v) => v?.user?.userAuthId === currentTrip?.drivers[0]?.driverAuthId
      );
      if (getLatestDriverDetails?.length > 0) {
        let tempTrip = currentTrip;
        tempTrip.driverDetails.coords = getLatestDriverDetails[0]?.coords;
        const checkUpdated = tempTrip?.driverDetails?.coords?.locationUpdated;
        tempTrip.driverDetails.coords.locationUpdated = checkUpdated
          ? parseInt(checkUpdated) + 1
          : 1;
        setCurrentTrip(tempTrip);
      }
    }
    setDriverLocationUpdated((prev) => !prev);
  };

  const handleDriverDetails = (data) => {
    // alert("Drivers Online");
    if (availableDriversDetail?.length > 0) {
      let tempDriverDetails = [...availableDriversDetail];
      data?.forEach((userAuthId) => {
        const currentDataIndex = tempDriverDetails?.findIndex(
          (item) => item?.user?.userAuthId === userAuthId
        );
        if (currentDataIndex !== -1)
          tempDriverDetails[currentDataIndex].isOnline = true;
      });
      setAvailableDriversDetail(tempDriverDetails);
    }
    //  else {
    //   fetchDriversOnlineFromAPI(data);
    // }
  };

  const handleDriverDetailsUpdate = (newData) => {
    if (newData) {
      setAvailableDriversDetail((prev) => {
        if (prev?.length > 0) {
          const currentDataIndex = prev?.findIndex(
            (item) => item?.user?.userAuthId === newData?.userAuthId
          );

          if (currentDataIndex !== -1) {
            const newDataValues = [...prev];
            newDataValues[currentDataIndex].coords = newData?.location?.coords;
            return newDataValues;
          }

          return prev;
        }
      });
    }
  };

  const handleDriverOffline = (driver) => {
    if (driver?.length > 0 && availableDriversDetail?.length > 0) {
      const updatedData = [...availableDriversDetail];
      const getIndex = updatedData?.findIndex(
        (item) => item?.user?.userAuthId === driver[0]?.userId
      );
      if (getIndex !== -1) {
        updatedData[getIndex].isOnline = false;
        if (driver[0]?.reason === "client namespace disconnect") {
          updatedData[getIndex].user.status = UNAVAILABLE;
        }
      }
      setAvailableDriversDetail(updatedData || []);
    }
  };

  const handleDriverOnline = (driver) => {
    if (driver?.length > 0 && availableDriversDetail?.length > 0) {
      console.log("driverSocket : Online", driver);
      const updatedData = [...availableDriversDetail];
      const getIndex = updatedData?.findIndex(
        (item) => item?.user?.userAuthId === driver[0]
      );
      if (getIndex !== -1) updatedData[getIndex].isOnline = true;
      setAvailableDriversDetail(updatedData || []);
    }
  };

  useEffect(() => {
    if (socket?.connected && locationUpated) {
      console.log("driversonline : Client requests data");
      socket.emit("driversonline", { message: "Client requests data" });
    }

    if (socket?.connected) {
      socket.on("driversonline", (data) => {
        console.log("driversonline :", data);
        handleDriverDetails(data);
        setLocationUpdated(false);
      });

      socket.on("useroffline", (data) => {
        handleDriverOffline(data);
        console.log(data, "User Offline");
      });

      socket.on("useronline", (data) => {
        handleDriverOnline(data);
        console.log(data, "User Online");
      });

      socket.on("newlocation", (data) => {
        handleDriverDetailsUpdate(data);
        console.log(data, "User newlocation");
      });

      // socket.on("useroffline", (data) => {
      //   handleDriverOffline(data);
      //   console.log(data, "User Offline");
      // });
    }
  }, [socket, locationUpated]);

  useEffect(() => {
    dispatch(
      readTodayTripAction({
        apiPayloadRequest: {
          startDate: filterRange[0]?.startDate,
          endDate: filterRange[0]?.endDate,
        },
        callback: (res) => {
          if (res.type === 1) {
            setTripData(res?.data);
          }
        },
      })
    );
    dispatch(
      readTripAnalyticsAction({
        apiPayloadRequest: {
          startDate: filterRange[0]?.startDate,
          endDate: filterRange[0]?.endDate,
        },
        callback: (res) => {
          if (res.type === 1) {
            const emergencyTrips =
              res?.data?.[0]?.countByTypeOfTrip?.find(
                (d) => d?._id === "Emergency"
              )?.count ?? 0;
            const nonEmergencyTrips =
              res?.data?.[0]?.countByTypeOfTrip?.find(
                (d) => d?._id === "Non-Emergency"
              )?.count ?? 0;
            const totalEarnings =
              res?.data?.[0]?.totalEarnings?.[0]?.totalEarnings?.[
                "$numberDecimal"
              ] ?? 0;

            setTripAnalyticsData({
              emergencyTrips: emergencyTrips,
              nonEmergencyTrips: nonEmergencyTrips,
              totalEarnings: totalEarnings,
            });
          }
        },
      })
    );
  }, [filterRange]);

  return (
    <div className="flex bg-zinc-200">
      <NavBar active={"AnalyticsPage"} />
      <div className="flex w-full bg-pinl-400">
        <div className="w-full">
          <Header title={"Welcome"} />
          <div className="flex h-[88vh] flex-col w-full gap-4 pl-3 pr-4 overflow-auto">
            <div className="w-full flex justify-end">
              <DateFilter range={filterRange} setRange={setFilterRange} />
            </div>
            <div className="flex flex-row gap-4">
              <AnalyticsCard
                label={"Total  Emergency  Trips"}
                count={tripAnalyticsData?.emergencyTrips}
                img1={Ebar1trip}
                img2={Elogo1trip}
              />

              <AnalyticsCard
                label={"Total  Non  Emergency  Trips"}
                count={tripAnalyticsData?.nonEmergencyTrips}
                img1={Ebar2}
                img2={Elogo2}
              />

              <AnalyticsCard
                label={"Total  Revenue"}
                count={tripAnalyticsData?.totalEarnings}
                img1={Ebar3}
                img2={Elogo3}
              />
            </div>

            <div className="rounded-xl mb-1 w-full bg-white p-6">
              <DynamicTable
                size={7}
                data={tripData.filter((item) => item.status !== "0")}
                headers={todayTripsHeaders}
                showEditIcon={false}
                showDeleteIcon={false}
                search={true}
                title="Trips"
                width={"w-42"}
                heightDesktop={"h-[50%]"}
                handlePencilClick={(row) => {
                  console.log(row);
                  navigate(`/dashboard/${row?._id}`);
                }}
                showTrackIcon={true}
                handleTrackClick={async (row) => {
                  console.log("row", row);
                  let driv = availableDriversDetail;
                  const currentDriver =
                    driv?.length > 0 && row?.drivers?.length > 0
                      ? driv.filter(
                          (v) =>
                            v?.user?.userAuthId === row?.drivers[0]?.userAuthId
                        )
                      : [];

                  console.log("currentDriver", currentDriver);
                  // const getDistance = await DistanceFromDrop(currentDriver[0], row);
                  // curr.distance = getDistance;
                  if (currentDriver?.length > 0) {
                    // let curr = driv?.length > 0 ? currentDriver[0].lastLocation?.coords : "";
                    row.driverDetails =
                      currentDriver?.length > 0 ? currentDriver[0] : {};
                    // row.driverCurrentPosition = curr;
                  }
                  // setCurrentTrip(row);
                  setCurrentTripId(row?._id);
                  setTimeout(() => {
                    setShowMapsModal((preState) => !preState);
                  }, 200);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {showMapsModal && (
        <MapModel
          showMapsModal={showMapsModal}
          setShowMapsModal={setShowMapsModal}
          customStyles={customStyles}
          containerStyle={containerStyle}
          currentTrip={currentTrip}
          availableDriversDetail={availableDriversDetail}
          getLastLocation={getDriverLastLocation}
          driverLocationUpated={driverLocationUpated}
          addOnDetailsList={addOnDetailsList}
        />
      )}
    </div>
  );
};

export default AnalyticsPage;
