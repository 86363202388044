import Axios from "../../utils/axios";

export const saveRegion = (payload) => {
  const URL = "/region/save";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const savePincodeRegion = (payload) => {
  const URL = "/region/pincodes/save";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readRegion = (payload) => {
  const URL = "/region/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const deletePincodeRegion = (payload) => {
  const URL = "/region/pincodes/delete";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const updateRegion = (payload) => {
  const URL = "/region/update";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const deleteRegion = (payload) => {
  const URL = "/region/delete";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const saveAddon = (payload) => {
  const URL = "/addon/save";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const deleteAddon = (payload) => {
  const URL = "/addon/delete";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const updateAddon = (payload) => {
  const URL = "/addon/update";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readAddon = (payload) => {
  const URL = "/addon/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const saveVehicle = (payload) => {
  const URL = "/vehicle/save";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const saveAddonVehicle = (payload) => {
  const URL = "/vehicle/addon/save";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const saveUserVehicleType = (payload) => {
  const URL = "/user/vehicletype/save";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const deleteUserVehicleType = (payload) => {
  const URL = "/user/vehicletype/delete";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readVehicle = (payload) => {
  const URL = "/vehicle/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const deleteVehicle = (payload) => {
  const URL = "/vehicle/delete";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const deleteAddonVehicle = (payload) => {
  const URL = "/vehicle/addon/delete";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const updateVehicle = (payload) => {
  const URL = "/vehicle/update";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const saveTarrif = (payload) => {
  const URL = "/tarrif/save";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const saveAddonTarrif = (payload) => {
  const URL = "/tarrif/addon/save";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readTarrif = (payload) => {
  const URL = "/tarrif/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readTripTarrif = (payload) => {
  const URL = "/trip/getTarrif";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const deleteTarrif = (payload) => {
  const URL = "/tarrif/delete";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const updateTarrif = (payload) => {
  const URL = "/tarrif/update";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const updateAddonTarrif = (payload) => {
  const URL = "/tarrif/addon/update";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const saveDiscount = (payload) => {
  const URL = "/discount/save";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readDiscount = (payload) => {
  const URL = "/discount/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const deleteDiscount = (payload) => {
  const URL = "/discount/delete";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const updateDiscount = (payload) => {
  const URL = "/discount/update";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const deactivateDiscount = (payload) => {
  const URL = "/discount/deactivate";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readDriversLocations = (payload) => {
  const URL = "/readDriversLocations";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readDriver = (payload) => {
  const URL = "/drivers/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const deactivateDriver = (payload) => {
  const URL = "/drivers/activate";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const deleteDriver = (payload) => {
  const URL = "/drivers/delete";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readAgent = (payload) => {
  const URL = "/agents/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const deactivateAgent = (payload) => {
  const URL = "/agents/activate";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const saveAgent = (payload) => {
  const URL = "/agents/save";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readTypeOfUsers = (payload) => {
  const URL = "/typeofusers/";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const saveTrip = (payload) => {
  const URL = "/trip/save";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const updateTrip = (payload) => {
  const URL = "/trip/update";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readTrip = (payload) => {
  const URL = "/trip/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readTodayTrip = (payload) => {
  const URL = "/trip/todaysTrips";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readAnalyticsTrip = (payload) => {
  const URL = "/trip/analytics";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const saveTripAdddon = (payload) => {
  const URL = "/trip/addon/save";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const updateTripAdddon = (payload) => {
  const URL = "/trip/addon/update";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const saveTripDriver = (payload) => {
  const URL = "/trip/driver/save";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const updateTripDriver = (payload) => {
  const URL = "/trip/driver/update";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const checkDriverBusy = () => {
  const URL = "/trip/checkdriverbusy";
  return Axios.post(URL).then((res) => res.data);
};

export const searchAddress = (payload) => {
  const URL = `/search`;
  return Axios.get(URL, {
    baseURL: "https://nominatim.openstreetmap.org",
    params: {
      q: payload.query,
      addressdetails: 1,
      format: "json",
    },
  }).then((res) => res.data);
};
