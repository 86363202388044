import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadScript } from "@react-google-maps/api";
// import Tempo from "../../../../assets/images/Tempo.png";
// import Ambulance from "../../../../assets/images/Ambulance.png";
// import Ambulance2 from "../../../../assets/images/Ambulance2.png";
// import Omni from "../../../../assets/images/Omni.png";
import Backarrow from "../../../../assets/images/Backarrow.png";
import TextInputField from "../../InputFields/TextInputField";
import InputDropdown from "../../InputFields/InputDropdown";
import LocationInput from "../../InputFields/LocationInput";
import PickDropLocation from "../Locations/PickDropLocation";
import ImageContainer from "../Containers/ImageContainer";
import Button from "../../Buttons/Button";
import { libraries } from "../../../../constants/Constant";
import { FaRupeeSign } from "react-icons/fa";
import {
  readDriverAction,
  readTripAction,
  readTripTarrifAction,
  readTypeOfUsersAction,
  readVehicleAction,
  saveQuickBookingLocation,
  saveQuickBookingLocationSuggestions,
  saveTripAction,
  saveTripAddonAction,
  updateTripAddonAction,
  saveTripDriverAction,
  updateTripDriverAction,
  updateTripAction,
  checkDriverBusyAction,
} from "../../../../store/Service/ServiceAction";
import CounterInputs from "../../InputFields/CounterInputs";
import Toastify from "../../../Universal/Toastify";
import AssignDriverContainer from "../Containers/AssignDriverContainer";
import MapLocationandTextLine from "../ClubbedComponents/MapLocationandTextLine";
import TextLine from "../../../Cards/TextLine";
import DriverContainer from "../Containers/DriverContainer";
import DateTimeInput from "../../InputFields/DateTimeInput";
import axios from "axios";
import _debounce from "lodash/debounce";
import { useParams } from "react-router-dom";
import { compareDate } from "../../../../utils/utilsHelperFunctions";
import LoaderSpinner from "../../../Universal/LoaderSpinner";
import { readUserByMobileAction } from "../../../../store/LoginAndRegister/LoginAndRegisterAction";
import OSMLocationInput from "../../InputFields/OSMLocationInput";

const QuickBooking = ({
  initialLocationValues,
  initialBookingValues,
  initialErrorFields,
  bookingValues,
  setBookingValues,
  locationInput,
  setLocationInput,
  errorFields,
  setErrorFields,
  currentTab,
  setCurrentTab,
  tripData,
  setTripData,
  onLocationSelect,
  result,
  suggestions,
  driverDetails,
  setLocationUpdated,
  driverStatus,
  setCurrentDriverIndex,
  setTripObj,
  setBookingStatus,
  setAssignedDriver,
  assignedDriver,
  setDriverStatus,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  console.log("locationInput111", locationInput);
  console.log("bookingValues111", bookingValues);
  console.log("errorFields11", errorFields);

  const [allVehicleData, setAllVehicleData] = useState([]);
  const [selectedVehicleData, setSelectedVehicleData] = useState({});
  console.log("selectedVehicleData11", selectedVehicleData);
  console.log("allVehicleData11", allVehicleData);

  const [usersList, setUsersList] = useState([]);
  const [driversList, setDriversList] = useState([]);
  const [distance, setDistance] = useState({ km: "", time: "" });
  const [showNearbyPickups, setShowNearbyPickups] = useState(false);
  const [editID, setEditID] = useState("");
  const [currentTariff, setCurrentTariff] = useState({});
  const [timeToArrive, setTimeToArrive] = useState("-");
  const [pickUpFormattedAddress, setPickUpFormattedAddress] = useState("");
  const [dropFormattedAddress, setDropFormattedAddress] = useState("");
  const [tarrifDetailsLoading, setTarrifDetailsLoading] = useState(false);
  const [tripUserDetails, setTripUserDetails] = useState({});

  console.log("currentTariff", currentTariff);
  console.log("usersList", usersList);
  console.log("distance", distance);

  useEffect(() => {
    if (driverDetails?.length > 0 && assignedDriver?.user?.userAuthId) {
      const updatedDriverDetails = driverDetails?.find(
        (d) => d?.user?.userAuthId === assignedDriver?.user?.userAuthId
      );
      setAssignedDriver(updatedDriverDetails);
    }
  }, [driverDetails]);

  const [refreshing, setRefreshing] = useState(false);

  const getCurrentTariffDetails = (
    selectedVehicleData,
    locationDetails = null,
    distanceDetails = null
  ) => {
    setTarrifDetailsLoading(true);
    setCurrentTariff([]);
    const actualLocation = locationDetails ?? locationInput;
    const actualDistance = distanceDetails ?? distance;
    dispatch(
      readTripTarrifAction({
        apiPayloadRequest: {
          _id: editID ?? id,
          fromLatitude: actualLocation?.pickup?.[0]?.location?.lat,
          fromLongitude: actualLocation?.pickup?.[0]?.location?.lng,
          fromPincode: actualLocation.pickup?.[0]?.postalCode,
          fromPlaceId: actualLocation?.pickup?.[0]?.placeId,
          vehicleObjId: selectedVehicleData?._id,
          distance:
            actualDistance?.km?.split(" ")[1] === "km"
              ? actualDistance?.km?.split(" ")[0]
              : parseFloat(actualDistance?.km?.split(" ")[0]) / 1000,
        },
        callback: (res) => {
          if (res?.type === 1) {
            setCurrentTariff(res?.data[0]);
          } else if (res?.type === 2) {
            setCurrentTariff([]);
            Toastify(res?.message, 2);
          }
          setTarrifDetailsLoading(false);
        },
      })
    );
  };

  // const handleRefreshDriverLIst = () => {
  //   setLocationUpdated(true);
  //   setRefreshing(true);
  // };

  useEffect(() => {
    if (refreshing) {
      setTimeout(() => {
        setRefreshing(false);
      }, 1000);
    }
  }, [refreshing]);

  useEffect(() => {
    dispatch(
      readVehicleAction({
        callback: (res) => {
          if (res?.type === 1) {
            setAllVehicleData(res?.data);
          }
        },
      })
    );
    dispatch(
      readTypeOfUsersAction({
        callback: (res) => {
          if (res?.type === 1 || res?.length > 0) {
            setUsersList(res);
          }
        },
      })
    );
    dispatch(
      readDriverAction({
        callback: (res) => {
          if (res?.type === 1) {
            setDriversList(res?.data);
          }
        },
      })
    );
  }, []);

  // Check if there is data in the features' addonObjId arrays
  const checkFeaturesAvailable = (item) => {
    return (
      item.features &&
      item.features.some(
        (feature) => feature.addonObjId && feature.addonObjId.length > 0
      )
    );
  };

  useEffect(() => {
    if (locationInput.pickup || locationInput.drop) {
      console.log("test4444444444");
      onLocationSelect(locationInput);
    }
  }, [locationInput]);

  useEffect(() => {
    if (result && result?.routes) {
      const route = result?.routes;
      const distanceCalculated = route[0]?.legs[0]?.distance?.text;
      const durationCalculated = route[0]?.legs[0]?.duration?.text;
      if (distanceCalculated)
        setDistance({ km: distanceCalculated, time: durationCalculated });
    } else {
      setDistance({ km: "", time: "" });
      setLocationInput(initialLocationValues);
    }
  }, [result]);

  const handleLocationInputChange = (data, name) => {
    // if (name === "pickup") {
    //   setLocationUpdated(true);
    // }
    console.log("data", data);
    console.log("name", name);
    setLocationInput((prev) => ({
      ...prev,
      [name]: data,
    }));

    setErrorFields({ ...errorFields, [name]: false });
  };

  const handleLocationInputDataChange = (value, name, field) => {
    setLocationInput((prev) => ({
      ...prev,
      [name]: [{ ...(prev?.[name]?.[0] ?? {}), [field]: value }],
    }));

    setErrorFields({ ...errorFields, [name]: false });
    searchPlaceByPincode(value, name, field);
  };

  const handleInputSuggestion = (e) => {
    const selectedValue = e.target.value;
    const selectedSuggestion = suggestions?.find(
      (v) => v.placeId === selectedValue
    );
    setLocationInput({
      ...locationInput,
      drop: selectedSuggestion ? [selectedSuggestion] : null,
    });
  };

  const handleVehicleSelect = (i) => {
    let currentVehicleData = structuredClone(allVehicleData[i]);

    setErrorFields({ ...errorFields, vehicle: false });
    const featuresList = checkFeaturesAvailable(currentVehicleData)
      ? currentVehicleData?.features?.map((v) => v?.addonObjId[0])
      : [];

    console.log("currentVehicleData11", currentVehicleData);
    if (featuresList?.length > 0) {
      currentVehicleData.featuresList = featuresList;
      setSelectedVehicleData(currentVehicleData);
      getCurrentTariffDetails(currentVehicleData);
    }
  };

  const handleFeatureQuantitySelect = (featureIndex, count) => {
    let vehicleFeatures = selectedVehicleData?.featuresList ?? [];
    setErrorFields({ ...errorFields, vehicleQuantity: false });
    vehicleFeatures[featureIndex].quantity = count;
    setSelectedVehicleData((prev) => ({
      ...prev,
      featuresList: vehicleFeatures,
    }));
    saveAddonsDetails(vehicleFeatures);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setBookingValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrorFields({ ...errorFields, [name]: false });
  };

  // const handleLocationInputChange = (event) => {
  //   const { name, value } = event.target;

  //   setLocationInput((prev) => ([{
  //     ...prev[0],
  //     [name]: value,
  //   }]));

  //   setErrorFields({ ...errorFields, [name]: false });
  // };

  const handleDateTimeChange = (value) => {
    setBookingValues((prev) => ({
      ...prev,
      dateandTimeofTrip: value,
    }));
    setErrorFields({ ...errorFields, dateandTimeofTrip: false });
  };

  const validateMobile = (value) => {
    return /^[6789]\d{9}$/.test(value);
  };

  console.log("editID", editID);

  const handleTripSubmit = () => {
    setDriverStatus("");
    let error = {};
    if (!bookingValues?.callerName) {
      error["callerName"] = true;
    }
    if (!bookingValues?.dateandTimeofTrip) {
      error["dateandTimeofTrip"] = true;
    }
    if (!bookingValues?.phone) {
      error["phone"] = true;
    }
    if (bookingValues?.phone && !validateMobile(bookingValues?.phone)) {
      error["phoneFormat"] = true;
    }
    if (!bookingValues?.userType) {
      error["userType"] = true;
    }
    if (!locationInput?.pickup) {
      error["pickup"] = true;
    }
    if (!locationInput?.drop) {
      error["drop"] = true;
    }

    console.log("44error", error);

    if (Object.keys(error)?.length > 0) {
      setErrorFields({ ...errorFields, ...error });
      return false;
    }

    const getTypeOfUser = usersList?.find(
      (v) => v.r === bookingValues?.userType
    );

    if (!editID) {
      dispatch(
        saveTripAction({
          apiPayloadRequest: {
            bookinguserAuthId: tripUserDetails?.userAuthId ?? "",
            callerName: bookingValues?.callerName,
            callerPhoneNumber: bookingValues?.phone,
            typeOfUser: getTypeOfUser?.roleName,
            typeOfUserCode: getTypeOfUser?.r,
            fromLatitude: locationInput?.pickup?.[0]?.location?.lat,
            fromLongitude: locationInput?.pickup?.[0]?.location?.lng,
            fromPincode: locationInput.pickup?.[0]?.postalCode,
            fromPlaceId: locationInput?.pickup?.[0]?.placeId,
            fromAddress: locationInput?.pickup?.[0]?.formattedAddress,
            toLatitude: locationInput?.drop?.[0]?.location?.lat,
            toLongitude: locationInput?.drop?.[0]?.location?.lng,
            toPincode: locationInput.drop?.[0]?.postalCode,
            toPlaceId: locationInput?.drop?.[0]?.placeId,
            toAddress: locationInput?.drop?.[0]?.formattedAddress,
            comments: bookingValues?.comments,
            timeOfBookingRequest: bookingValues?.dateandTimeofTrip,
            typeofTrip: bookingValues?.typeofTrip,
            distance:
              distance.km.split(" ")[1] === "km"
                ? distance.km.split(" ")[0]
                : parseFloat(distance.km.split(" ")[0]) / 1000,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const result = res?.data;
              setEditID(result[0]?._id);

              Toastify("Trip Saved Successfully!", 1);
              setCurrentTab(2);
              dispatch(
                checkDriverBusyAction({
                  callback: (response) => {
                    console.log("checkDriverBusy", response);
                  },
                })
              );
            } else if (res?.type === 2 || res?.type === 3) {
              Toastify(res?.message, 2);
            }
          },
        })
      );
    } else {
      dispatch(
        updateTripAction({
          apiPayloadRequest: {
            _id: editID,
            callerName: bookingValues?.callerName,
            callerPhoneNumber: bookingValues?.phone,
            typeOfUser: getTypeOfUser?.roleName,
            typeOfUserCode: getTypeOfUser?.r,
            fromLatitude: locationInput?.pickup[0]?.location?.lat,
            fromLongitude: locationInput?.pickup[0]?.location?.lng,
            fromPincode: locationInput.pickup[0]?.postalCode,
            fromPlaceId: locationInput?.pickup[0]?.placeId,
            fromAddress: locationInput?.pickup[0]?.formattedAddress,
            toLatitude: locationInput?.drop[0]?.location?.lat,
            toLongitude: locationInput?.drop[0]?.location?.lng,
            toPincode: locationInput.drop[0]?.postalCode,
            toPlaceId: locationInput?.drop[0]?.placeId,
            toAddress: locationInput?.pickup[0]?.formattedAddress,
            comments: bookingValues?.comments,
            // dateandTimeofTrip: bookingValues?.dateandTimeofTrip,
            typeofTrip: bookingValues?.typeofTrip,
            distance:
              distance.km.split(" ")[1] === "km"
                ? distance.km.split(" ")[0]
                : parseFloat(distance.km.split(" ")[0]) / 1000,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastify("Trip Updated Successfully!", 1);
              setCurrentTab(2);
              dispatch(
                checkDriverBusyAction({
                  callback: (response) => {
                    console.log("checkDriverBusy", response);
                  },
                })
              );
            } else if (res?.type === 2 || res?.type === 3) {
              Toastify(res?.message, 2);
            }
          },
        })
      );
    }
  };

  const saveAddonsDetails = (vehicleFeatures = null) => {
    const actualVehicleFeatures =
      vehicleFeatures ?? selectedVehicleData?.featuresList;
    actualVehicleFeatures?.map(async (item) => {
      const singleprice =
        currentTariff?.addontarrifs?.find((d) => d?.addonObjId === item?._id)
          ?.amount ?? 0;
      if (item?.isSaved) {
        dispatch(
          updateTripAddonAction({
            apiPayloadRequest: {
              _id: editID,
              addonObjId: item?._id,
              addonCount: item?.quantity,
              singleprice: singleprice,
              totalprice: singleprice * (item?.quantity ?? 0),
            },
            callback: (res) => {
              if (res?.type === 1) {
                //updated equipmentArray
              }
            },
          })
        );
      } else {
        dispatch(
          saveTripAddonAction({
            apiPayloadRequest: {
              _id: editID,
              addonObjId: item?._id,
              addonCount: item?.quantity ?? 0,
              singleprice: singleprice,
              totalprice: singleprice * (item?.quantity ?? 0),
            },
            callback: (res) => {
              if (res?.type === 1) {
                //updated equipmentArray
              }
            },
          })
        );
      }
    });
    setSelectedVehicleData((prev) => {
      const newfeatureList = prev?.featuresList?.map((d) => ({
        ...d,
        isSaved: true,
      }));
      return { ...prev, featuresList: newfeatureList };
    });
    const updatedTotalCharges =
      currentTariff?.calculatedcharges +
      currentTariff?.addontarrifs?.reduce((prev, item) => {
        const amount = actualVehicleFeatures?.find(
          (feature) => feature?._id === item?.addonObjId
        )?.quantity;
        console.log("calculation amount", amount);
        console.log("calculation amount item?.amount", item?.amount);
        return prev + (item?.amount ? Number(item?.amount) : 0) * (amount ?? 0);
      }, 0);
    dispatch(
      updateTripAction({
        apiPayloadRequest: {
          _id: editID,
          vehicleObjId: selectedVehicleData?._id,
          price: updatedTotalCharges ?? 0,
          distance:
            distance.km.split(" ")[1] === "km"
              ? distance.km.split(" ")[0]
              : parseFloat(distance.km.split(" ")[0]) / 1000,
        },
        callback: (res) => {
          if (res?.type === 1) {
            Toastify("Trip Updated Successfully!", 1);
          } else if (res?.type === 2 || res?.type === 3) {
            Toastify(res?.message, 2);
          }
        },
      })
    );
  };

  useEffect(() => {
    if (driverStatus === "A") {
      console.log("driverStatus", driverStatus);
      console.log("driverStatus accepted");
      const totaladdonprice = selectedVehicleData?.featuresList?.reduce(
        (prev, item) => {
          const amount = currentTariff?.addontarrifs?.find(
            (d) => d?.addonObjId === d?._id
          )?.amount;
          return prev + (amount ?? 0) * (item?.quantity ?? 0);
        },
        0
      );
      console.log("totaladdonprice", totaladdonprice);
      console.log("payload", {
        _id: editID,
        // driverAuthId: assignedDriver?.userAuthId,
        driverObjId: assignedDriver?._id,
        acceptedLatitude: locationInput?.pickup[0]?.location?.lat,
        acceptedLogitude: locationInput?.pickup[0]?.location?.lng,
        distanceToPickup:
          distance.km.split(" ")[1] === "km"
            ? distance.km.split(" ")[0]
            : parseFloat(distance.km.split(" ")[0]) / 1000,
        tripprice: currentTariff?.calculatedcharges,
        totaladdonprice: totaladdonprice,
      });
      dispatch(
        updateTripDriverAction({
          apiPayloadRequest: {
            _id: editID,
            // driverAuthId: assignedDriver?.userAuthId,
            driverObjId: assignedDriver?._id,
            acceptedLatitude: locationInput?.pickup[0]?.location?.lat,
            acceptedLogitude: locationInput?.pickup[0]?.location?.lng,
            distanceToPickup:
              distance.km.split(" ")[1] === "km"
                ? distance.km.split(" ")[0]
                : parseFloat(distance.km.split(" ")[0]) / 1000,
            tripprice: currentTariff?.calculatedcharges,
            totaladdonprice: totaladdonprice,
          },
          callback: (res) => {
            if (res?.type === 1) {
              //updated driver
              Toastify("Driver Assigned Successfully!", 1);
              setCurrentTab(3);
            } else {
              Toastify("Driver Assigning Failed!", 2);
            }
          },
        })
      );
    }

    if (driverStatus === "R") {
      const totaladdonprice = selectedVehicleData?.featuresList?.reduce(
        (prev, item) => {
          const amount = currentTariff?.addontarrifs?.find(
            (d) => d?.addonObjId === d?._id
          )?.amount;
          return prev + (amount ?? 0) * (item?.quantity ?? 0);
        },
        0
      );
      dispatch(
        updateTripDriverAction({
          apiPayloadRequest: {
            _id: editID,
            // driverAuthId: assignedDriver?.userAuthId,
            driverObjId: assignedDriver?._id,
            acceptedLatitude: locationInput?.pickup[0]?.location?.lat,
            acceptedLogitude: locationInput?.pickup[0]?.location?.lng,
            distanceToPickup:
              distance.km.split(" ")[1] === "km"
                ? distance.km.split(" ")[0]
                : parseFloat(distance.km.split(" ")[0]) / 1000,
            tripprice: currentTariff?.calculatedcharges,
            totaladdonprice: totaladdonprice,
          },
          callback: (res) => {
            if (res?.type === 1) {
              //updated driver
              Toastify("Driver Rejected The Request", 2);
            } else {
              Toastify("Driver Assigning Failed!", 2);
            }
          },
        })
      );
    }
  }, [driverStatus]);

  const getTripTotalAmount = () => {
    const totalCharges =
      currentTariff?.calculatedcharges +
      currentTariff?.addontarrifs?.reduce((prev, item) => {
        const amount = selectedVehicleData?.featuresList?.find(
          (feature) => feature?._id === item?.addonObjId
        )?.quantity;
        return prev + (item?.amount ? Number(item?.amount) : 0) * (amount ?? 0);
      }, 0);
    return totalCharges ?? 0;
  };

  const handleTripDriverSubmit = (driverData, index) => {
    setDriverStatus("");
    const myFeatureList = selectedVehicleData?.featuresList.map((item) => ({
      feature:
        item?.addon + " " + (item?.quantity ?? 0) + " " + item?.measurement,
    }));

    setCurrentDriverIndex(index);
    setAssignedDriver(driverData);
    const totalCharges =
      currentTariff?.calculatedcharges +
      currentTariff?.addontarrifs?.reduce((prev, item) => {
        const quantity = selectedVehicleData?.featuresList?.find(
          (feature) => feature?._id === item?.addonObjId
        )?.quantity;
        return (
          prev + (item?.amount ? Number(item?.amount) : 0) * (quantity ?? 0)
        );
      }, 0);
    dispatch(
      updateTripAction({
        apiPayloadRequest: {
          _id: editID,
          vehicleObjId: selectedVehicleData?._id,
          price: totalCharges ?? 0,
          distance:
            distance.km.split(" ")[1] === "km"
              ? distance.km.split(" ")[0]
              : parseFloat(distance.km.split(" ")[0]) / 1000,
        },
        callback: (res) => {
          if (res?.type === 1) {
            Toastify("Trip Updated Successfully!", 1);
          } else if (res?.type === 2 || res?.type === 3) {
            Toastify(res?.message, 2);
          }
        },
      })
    );
    setTripObj({
      _id: driverData._id,
      tripId: editID,
      distance:
        distance?.km?.split(" ")[1] === "km"
          ? distance?.km?.split(" ")[0]
          : parseFloat(distance?.km?.split(" ")[0]) / 1000,
      typeofTrip: bookingValues?.typeofTrip,
      vehicleObjId: selectedVehicleData?._id,
      vehicleName:
        selectedVehicleData?.vehicle_type_name +
        " " +
        selectedVehicleData?.vehicle_service,
      featuresList: myFeatureList,
      price: totalCharges,
      calculatedcharges: currentTariff?.calculatedcharges,
      pickup: locationInput?.pickup?.[0]?.location,
      fromAddress: locationInput?.pickup?.[0]?.formattedAddress,
      drop: locationInput?.drop?.[0]?.location,
      toAddress: locationInput?.drop?.[0]?.formattedAddress,
      callerName: bookingValues?.callerName,
      callerPhoneNumber: bookingValues?.phone,
    });

    dispatch(
      saveTripDriverAction({
        apiPayloadRequest: {
          _id: editID,
          // driverAuthId: assignedDriver?.userAuthId,
          driverObjId: driverData._id,
          acceptedLatitude: locationInput?.pickup[0]?.location?.lat,
          acceptedLogitude: locationInput?.pickup[0]?.location?.lng,
          distanceToPickup:
            distance.km.split(" ")[1] === "km"
              ? distance.km.split(" ")[0]
              : parseFloat(distance.km.split(" ")[0]) / 1000,
        },
        callback: (res) => {
          if (res?.type === 1) {
            //updated driver
            // Toastify("Driver Assigned Successfully!", 1);
          } else {
            Toastify("Driver Assigning Failed!", 2);
          }
        },
      })
    );

    setTimeout(() => {
      setBookingStatus(true);
    }, 500);
  };

  const resetBooking = () => {
    setBookingValues(initialBookingValues);
    setLocationInput(initialLocationValues);
    setErrorFields(initialErrorFields);
    setPickUpFormattedAddress("");
    setDropFormattedAddress("");
    setAllVehicleData([]);
    setSelectedVehicleData([]);
    setAssignedDriver(null);
    setDriverStatus("");
    setTripData([]);
    setDistance({ km: "", time: "" });
    setShowNearbyPickups(false);
    setEditID("");
    dispatch(saveQuickBookingLocation(null));
    dispatch(saveQuickBookingLocationSuggestions([]));
    setCurrentTab(1);
  };

  const userTypesOptions = useMemo(() => {
    return usersList?.length > 0
      ? usersList?.map(({ r, roleName }) => ({ label: roleName, value: r }))
      : [];
  }, [usersList]);
  console.log("userTypesOptions", userTypesOptions);

  useEffect(() => {
    if (id && userTypesOptions?.length > 0 && allVehicleData?.length > 0) {
      dispatch(
        readTripAction({
          apiPayloadRequest: {
            _id: id,
          },
          callback: (res) => {
            if (res.type === 1) {
              const data = res?.data[0];
              setTripData(res?.data);
              const userType = userTypesOptions?.find(
                (d) => d?.label === data?.typeOfUser
              )?.value;
              setBookingValues({
                callerName: data?.callerName,
                dateandTimeofTrip: data?.timeOfBookingRequest
                  ? new Date(data?.timeOfBookingRequest)
                  : "",
                typeofTrip: data?.typeofTrip,
                phone: data?.callerPhoneNumber,
                userType: userType,
                comments: data?.comments,
                price: 8000,
              });

              const distanceDetails = { km: data?.distance + " km", time: "" };

              setDistance(distanceDetails);
              setPickUpFormattedAddress(data?.fromAddress);
              setDropFormattedAddress(data?.toAddress);
              const fromLocation = {
                lat: data?.fromLatitude ? parseFloat(data?.fromLatitude) : 0,
                lng: data?.fromLongitude ? parseFloat(data?.fromLongitude) : 0,
              };
              const toLocation = {
                lat: data?.toLatitude ? parseFloat(data?.toLatitude) : 0,
                lng: data?.toLongitude ? parseFloat(data?.toLongitude) : 0,
              };
              const locationInputDetails = {
                pickup: [
                  {
                    location: fromLocation,
                    position: fromLocation,
                    geometry: {
                      location: fromLocation,
                    },
                    formattedAddress: data?.fromAddress,
                    placeName: data?.fromAddress
                      ?.split(" ")
                      .slice(0, 2)
                      .join(" "),
                    postalCode: data?.fromPincode,
                  },
                ],
                drop: [
                  {
                    location: toLocation,
                    position: toLocation,
                    geometry: {
                      location: toLocation,
                    },
                    formattedAddress: data?.toAddress,
                    placeName: data?.toAddress
                      ?.split(" ")
                      .slice(0, 2)
                      .join(" "),
                    postalCode: data?.toPincode,
                  },
                ],
              };

              setLocationInput(locationInputDetails);
              setCurrentTab(2);
              setEditID(id);

              //update selected vehicle details
              let currentVehicleData = allVehicleData?.find(
                (v) => v?._id === data?.vehicleObjId?.[0]?._id
              );
              console.log("currentVehicleData15", currentVehicleData);
              if (currentVehicleData) {
                let featuresList = checkFeaturesAvailable(currentVehicleData)
                  ? currentVehicleData?.features?.map((v) => v?.addonObjId[0])
                  : [];
                console.log("data?.addons", data?.addons);
                if (featuresList?.length > 0) {
                  if (data?.addons?.length > 0) {
                    featuresList = featuresList?.map((d) => {
                      const availableAddon = data?.addons?.find(
                        (v) => v?._id === d?._id
                      );
                      if (availableAddon) {
                        return {
                          ...d,
                          quantity: Number(availableAddon?.count ?? 0),
                          isSaved: true,
                        };
                      }
                      return d;
                    });
                  }
                  console.log("featuresList", featuresList);
                  currentVehicleData.featuresList = featuresList;
                  setSelectedVehicleData(currentVehicleData);
                  getCurrentTariffDetails(
                    currentVehicleData,
                    locationInputDetails,
                    distanceDetails
                  );
                }
              }
            }
          },
        })
      );
    }
  }, [id, userTypesOptions, allVehicleData]);

  const tripOptions = [
    {
      label: "Non-Emergency",
      value: "Non-Emergency",
    },
    {
      label: "Emergency",
      value: "Emergency",
    },
  ];

  const suggestOptions =
    suggestions?.length > 0
      ? suggestions?.map(({ placeName, rating, placeId }) => ({
          label: rating ? `${placeName} ${rating} ⭐` : placeName,
          value: placeId,
        }))
      : [];

  function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  }

  const searchPlaceByPincode = useCallback(
    _debounce((value, name, field) => {
      axios(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${value}&key=${process.env.REACT_APP_MAP_API_KEY}`
      )
        .then(({ data }) => {
          const place = data?.results?.[0] ?? {};
          console.log("geocode data", data);
          if (place?.geometry) {
            const location = {
              lat: place.geometry.location.lat,
              lng: place.geometry.location.lng,
            };
            const geometry = place?.geometry;
            const position = geometry.location;
            const placeId = place.place_id;
            const placeName = place.name;
            const formattedAddress = place.formatted_address;

            if (name === "pickup")
              setPickUpFormattedAddress(place.formatted_address);
            else if (name === "drop")
              setDropFormattedAddress(place.formatted_address);

            let postalCode = value;
            console.log("name12", name);
            handleLocationInputChange(
              [
                {
                  position,
                  placeId,
                  placeName,
                  geometry,
                  location,
                  postalCode,
                  formattedAddress,
                },
              ],
              name
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 1000),
    []
  );

  const searchPlaceByPincodeOSM = useCallback(
    _debounce((value, name, field) => {
      axios(
        `https://nominatim.openstreetmap.org/search?postalcode=${value}&format=json&addressdetails=1`
      )
        .then(({ data }) => {
          const place = data?.[0] ?? {};
          console.log("geocode data", data);
          if (place?.address && place?.lat && place?.lon) {
            const location = {
              lat: place?.lat,
              lng: place?.lon,
            };
            const geometry = {
              location: location,
              boundingbox: place?.boundingbox,
            };
            const position = geometry?.location;
            const placeId = place?.place_id;
            const placeName = place?.name;
            const formattedAddress = place.display_name;

            if (name === "pickup")
              setPickUpFormattedAddress(place?.display_name);
            else if (name === "drop")
              setDropFormattedAddress(place.display_name);

            let postalCode = value;
            console.log("name12", name);
            handleLocationInputChange(
              [
                {
                  position,
                  placeId,
                  placeName,
                  geometry,
                  location,
                  postalCode,
                  formattedAddress,
                },
              ],
              name
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 1000),
    []
  );

  const searchUserByMobile = useCallback(
    _debounce((mobile) => {
      dispatch(
        readUserByMobileAction({
          apiPayloadRequest: {
            mobile: mobile,
          },
          callback: (res) => {
            if (res?.type === 1) {
              console.log("user details78", res);
              if (res?.data?.length > 0) {
                setBookingValues((prev) => ({
                  ...prev,
                  callerName: res?.data?.[0]?.firstName,
                  userType: "63udl9",
                }));
                setTripUserDetails(res?.data?.[0]);
              }
            } else if (res?.type === 2) {
              Toastify(res?.message, 2);
            }
            setTarrifDetailsLoading(false);
          },
        })
      );
    }, 1000),
    []
  );

  return (
    <div className="flex flex-col gap-5 h-[100vh] overflow-y-auto">
      <div className="px-5 pt-5">
        <p className="text-xl font-bold">Quick Booking</p>
      </div>
      <div
        className={`overflow-auto p-5 border-t ${
          currentTab !== 1 ? "hidden" : ""
        }`}
      >
        <div className="flex flex-col justify-between gap-3">
          <TextInputField
            name="phone"
            label="Phone Number"
            placeholder={"Enter Phone Number"}
            value={bookingValues?.phone}
            max={10}
            handleChange={(e) => {
              handleInputChange(e);
              searchUserByMobile(e.target.value);
              if (validateMobile(e.target.value)) {
                setErrorFields({ ...errorFields, phoneFormat: false });
              } else {
                setErrorFields({ ...errorFields, phoneFormat: true });
              }
            }}
            isError={errorFields?.phone}
            isFormatError={errorFields?.phoneFormat}
            isFormatErrorMessage={"Invalid Phone Number"}
          />
          <InputDropdown
            name="typeofTrip"
            label="Type Of Trip"
            options={tripOptions}
            styles={"border-2"}
            value={bookingValues?.typeofTrip}
            onChange={handleInputChange}
            isError={errorFields?.typeofTrip}
          />
          <DateTimeInput
            label={"Trip Date and Time"}
            handleChange={handleDateTimeChange}
            value={bookingValues?.dateandTimeofTrip}
            isError={errorFields?.dateandTimeofTrip}
            minDate={new Date()}
          />
          <TextInputField
            name="callerName"
            label="Caller Name"
            placeholder={"Enter Caller Name"}
            value={bookingValues?.callerName}
            handleChange={handleInputChange}
            isError={errorFields?.callerName}
          />

          <InputDropdown
            name="userType"
            label="Type of User"
            options={userTypesOptions}
            styles={"border-2"}
            value={bookingValues?.userType}
            onChange={handleInputChange}
            isError={errorFields?.userType}
          />
        </div>
        <div className="flex relative pt-5">
          <PickDropLocation height="h-36" />
          <div className="flex flex-col justify-center w-full">
            <LocationInput
              name="pickup"
              label="Pick Up Point"
              placeholder="Enter Pick Up Point"
              value={locationInput.pickup}
              onChange={handleLocationInputChange}
              formattedAddress={pickUpFormattedAddress}
              setFormattedAddress={setPickUpFormattedAddress}
            />
            {errorFields?.pickup && (
              <p className="text-red-500 relative bottom-[-4px] text-[10px] left-2 mb-[-6px]">
                Pickup Is Required Field
              </p>
            )}
            <TextInputField
              name="postalCode"
              label="Pickup Postal Code"
              placeholder={"Enter Pick Up Postal Code"}
              value={locationInput?.pickup?.[0]?.postalCode ?? 0}
              handleChange={(e) =>
                handleLocationInputDataChange(
                  e.target.value,
                  "pickup",
                  "postalCode"
                )
              }
              containerStyle="mx-4 py-3"
              inputstyle="px-0 border-none"
              // isError={errorFields?.callerName}
            />
            {suggestions?.length > 0 && (
              <div
                className="text-xs text-end text-blue-400 cursor-pointer"
                onClick={() => setShowNearbyPickups((prev) => !prev)}
              >
                {showNearbyPickups
                  ? "Select Other Drop point"
                  : "Nearby hospital drop points"}
              </div>
            )}
            {showNearbyPickups && (
              <LocationInput
                name="suggestions"
                label="Nearby Drop Locations"
                options={suggestOptions}
                styles={"border-2"}
                onChange={handleInputSuggestion}
              />
            )}
            <hr className="h-1 w-full bg-secondary" />
            <div className={showNearbyPickups ? "hidden invisible" : ""}>
              <LocationInput
                name="drop"
                label="Drop Location"
                placeholder="Enter Drop Location"
                value={locationInput.drop}
                onChange={handleLocationInputChange}
                styles={showNearbyPickups ? "hidden invisible" : ""}
                formattedAddress={dropFormattedAddress}
                setFormattedAddress={setDropFormattedAddress}
              />
              {errorFields?.drop && (
                <p className="text-red-500 relative bottom-[-4px] text-[10px] left-2 mb-[-6px]">
                  Drop Is Required Field
                </p>
              )}
              <TextInputField
                name="postalCode"
                label="Drop Postal Code"
                placeholder={"Enter Pick Up Postal Code"}
                value={locationInput?.drop?.[0]?.postalCode ?? ""}
                handleChange={(e) =>
                  handleLocationInputDataChange(
                    e.target.value,
                    "drop",
                    "postalCode"
                  )
                }
                containerStyle="mx-4 py-3"
                inputstyle="px-0 border-none"
                // isError={errorFields?.callerName}
              />
            </div>
            {distance && (
              <div className="py-4 text-center">
                {distance?.km} {distance?.time}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col mt-2 gap-1">
          <div>
            <p className="text-xs font-medium">
              Patient Conditions
              <span className="text-[10px] text-slate-400"> (optional)</span>
            </p>
          </div>
          <div className="border-2 rounded-2xl w-full p-1">
            <textarea
              name="comments"
              placeholder="Enter your comments"
              rows={3}
              cols={35}
              className="w-full p-2 focus:outline-none"
              value={bookingValues?.comments}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <Button
          label="Next"
          background={"bg-[#F15A2A]"}
          textcolor={"text-white"}
          onClick={handleTripSubmit}
        />
      </div>
      <div className={`${currentTab !== 2 ? "hidden" : ""}`}>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}
          libraries={libraries}
          mapIds={[process.env.REACT_APP_MAP_ID]}
        >
          <div className="overflow-auto p-5 border-t">
            <div className="cursor-pointer" onClick={() => setCurrentTab(1)}>
              <img src={Backarrow} />{" "}
            </div>
            <div className="flex flex-col gap-3 my-2">
              <div>
                <MapLocationandTextLine
                  lineWidth1={"w-0"}
                  lineWidth2={"w-0"}
                  pickup={locationInput.pickup}
                  drop={locationInput.drop}
                />{" "}
              </div>
              <div>
                <TextLine
                  label={"Distance"}
                  value={distance.km}
                  styles={"font-semibold text-[14px] text-[#2B2B2B] "}
                  Linestyle={""}
                />
              </div>
              <div>
                <TextLine
                  label={"Trip Date"}
                  value={
                    bookingValues?.dateandTimeofTrip
                      ? bookingValues?.dateandTimeofTrip?.toLocaleDateString()
                      : ""
                  }
                  styles={"font-semibold text-[14px] text-[#2B2B2B] "}
                  Linestyle={""}
                />{" "}
              </div>
              <div className="font-bold text-sm ">Choose Ambulance</div>
              <div className="overflow-auto w-[100%]">
                {allVehicleData && allVehicleData?.length > 0 ? (
                  <div className="flex sticky w-1/2">
                    {allVehicleData?.map((vehicle, index) => (
                      <div
                        key={index}
                        className="cursor-pointer"
                        onClick={() => handleVehicleSelect(index)}
                      >
                        <ImageContainer
                          ImgUrl={vehicle?.vehicle_icon_url}
                          ImgName={`${vehicle?.vehicle_type_name} (${vehicle?.vehicle_service})`}
                          styles={`cursor-pointer ${
                            selectedVehicleData?._id === vehicle?._id
                              ? "bg-slate-400"
                              : ""
                          }`}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex sticky">
                    No Ambulance Found
                    {/* <ImageContainer ImgUrl={Omni} ImgName='Omni(BLS)' />
                        <ImageContainer ImgUrl={Tempo} ImgName='Tempo(ALS)' />
                        <ImageContainer ImgUrl={Ambulance} ImgName='Ambulance' />
                        <ImageContainer ImgUrl={Ambulance2} ImgName='Ambulance2' /> */}
                  </div>
                )}
              </div>
              {errorFields?.vehicle && (
                <p className="text-red-500 relative bottom-[-4px] text-[10px] left-2 mb-[8px]">
                  Select Anyone Vehicle
                </p>
              )}
              {tarrifDetailsLoading ? (
                <div className="py-6 flex flex-col justify-center items-center">
                  <LoaderSpinner className="!text-black" />
                  <p className="text-xs">Fetching tariff details..</p>
                </div>
              ) : (
                currentTariff?.charges &&
                !isEmpty(selectedVehicleData) &&
                Object.keys(selectedVehicleData)?.length > 0 && (
                  <div>
                    <div>
                      <div className="flex flex-col w-full gap-2 ">
                        {selectedVehicleData ? (
                          <div className="p-4 rounded-lg border">
                            <p className="text-lg font-bold mb-2">
                              Quantity Requirement
                            </p>
                            {selectedVehicleData?.featuresList?.length > 0 &&
                              selectedVehicleData?.featuresList?.map(
                                (feature, i) => (
                                  <div key={i}>
                                    <div>
                                      <p className="text-xs font-medium">
                                        How many {feature?.addon} do you need?
                                        <span className="text-[10px] text-slate-400">
                                          {" "}
                                          ({feature?.measurement})
                                        </span>
                                      </p>
                                    </div>

                                    <div className="flex flex-row gap-2">
                                      <CounterInputs
                                        name={feature?.addon}
                                        limit={5}
                                        value={
                                          feature?.quantity
                                            ? feature?.quantity
                                            : 0
                                        }
                                        onChange={(count) => {
                                          handleFeatureQuantitySelect(i, count);
                                        }}
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                        ) : (
                          <div className="p-4 py-10 rounded-lg bg-red-200 text-center font-semibold text-red-500">
                            We are not serving this region now !!
                          </div>
                        )}
                      </div>
                      {errorFields?.vehicleQuantity && (
                        <p className="text-red-500 relative bottom-[-4px] text-[10px] left-2 mb-[8px]">
                          Select Quantity For Equipments
                        </p>
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
            {currentTariff?.charges && !isEmpty(selectedVehicleData) && (
              <div className="p-4 border rounded-lg mt-6">
                <p className="text-lg font-bold mb-2">Bill Summary</p>
                {selectedVehicleData?.featuresList &&
                  selectedVehicleData?.featuresList?.map((feature, index) => (
                    <div key={index}>
                      {" "}
                      <TextLine
                        label={feature?.addon}
                        value={`${
                          feature?.quantity === undefined
                            ? "0"
                            : feature?.quantity
                        } ${feature?.measurement}`}
                        styles={"font-semibold text-[14px] text-[#2B2B2B] "}
                        Linestyle={
                          index ===
                          selectedVehicleData?.featuresList?.length - 1
                            ? "w-0"
                            : ""
                        }
                      />{" "}
                      <div
                        className={`flex justify-end items-center text-xs ${
                          feature?.quantity ? "text-red-500" : "text-white"
                        }  w-full`}
                      >
                        <FaRupeeSign size={8} />
                        <p>
                          {" "}
                          {parseInt(
                            currentTariff?.addontarrifs?.filter(
                              (item) => item?.addonObjId === feature?._id
                            )[0]?.amount
                              ? currentTariff?.addontarrifs?.filter(
                                  (item) => item?.addonObjId === feature?._id
                                )[0]?.amount
                              : 0
                          ) * parseInt(feature?.quantity)}
                        </p>
                      </div>
                    </div>
                  ))}
                <TextLine
                  label={"Trip Charge"}
                  value={currentTariff?.calculatedcharges}
                  styles={"font-semibold text-[14px] text-[#2B2B2B] "}
                  Linestyle={""}
                  rupee={true}
                />
                <p className="w-full h-[2px] rounded bg-black my-1 mt-6"></p>
                <TextLine
                  label={"Total"}
                  value={getTripTotalAmount()}
                  styles={"font-semibold text-[14px] text-[#2B2B2B] "}
                  Linestyle={""}
                  rupee={true}
                />
                <p className="w-full h-[2px] rounded bg-black "></p>
              </div>
            )}
            {currentTariff?.charges &&
              bookingValues?.dateandTimeofTrip &&
              compareDate(bookingValues?.dateandTimeofTrip, new Date()) ===
                0 && (
                <>
                  <div className="flex justify-between items-center">
                    <p className="font-bold text-md mb-2 mt-6">
                      Nearest Drivers
                    </p>
                    {/* <LuRefreshCw
              onClick={handleRefreshDriverLIst}
              className={`font-bold text-blue-900 cursor-pointer ${
                refreshing ? "animate-spin" : ""
              } `}
              size={18}
            /> */}
                  </div>
                  <div className="flex flex-col gap-2">
                    {driverDetails?.length > 0 &&
                      driverDetails
                        ?.filter(
                          (v) => v?.isOnline || v?.user?.status === "AVAILABLE"
                        )
                        ?.sort((d) => {
                          if (
                            d?.vehicleTypes?.some(
                              (d) =>
                                d?.vehicleObjId === selectedVehicleData?._id
                            )
                          ) {
                            return 1;
                          }
                          return 0;
                        })
                        ?.map((driver, index) => (
                          <div key={index}>
                            {" "}
                            <AssignDriverContainer
                              name={driver?.user?.firstName}
                              driverImageUrl={driver?.user?.profilePicUrl}
                              assign={
                                !isEmpty(selectedVehicleData) ? true : false
                              }
                              vehicleTypes={driver?.user?.vehicleTypes}
                              pickupLocation={
                                locationInput.pickup?.[0]?.location
                              }
                              driverLastLocation={driver?.user?.lastLocation}
                              coords={driver?.coords}
                              selectedVehicleData={selectedVehicleData}
                              number={driver?.user?.mobile}
                              // rating="4.2"
                              onClick={() =>
                                handleTripDriverSubmit(driver, index)
                              }
                            />
                          </div>
                        ))}
                  </div>
                </>
              )}
            {currentTariff?.charges &&
              bookingValues?.dateandTimeofTrip &&
              compareDate(bookingValues?.dateandTimeofTrip, new Date()) ===
                1 && (
                <div className="py-6 text-xl font-bold text-center">
                  Come back later to assign driver
                </div>
              )}
          </div>
        </LoadScript>
      </div>
      <div className={`${currentTab !== 3 ? "hidden" : ""}`}>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}
          libraries={libraries}
          mapIds={[process.env.REACT_APP_MAP_ID]}
        >
          <div className="overflow-auto p-5 border-t">
            {/* <div className="cursor-pointer" onClick={() => setCurrentTab(2)}>
              <img src={Backarrow} />
            </div> */}
            <div className="flex flex-row gap-2 items-center">
              <p className="font-bold text-[13px]">
                Your Ambulance is arriving in ({timeToArrive})
              </p>
              <img
                className="w-12"
                src={selectedVehicleData?.vehicle_icon_url}
              />
            </div>
            <div>
              <MapLocationandTextLine
                lineWidth1={"w-0"}
                lineWidth2={"w-0"}
                pickup={locationInput.pickup}
                drop={locationInput.drop}
              />{" "}
            </div>
            {selectedVehicleData?.featuresList &&
              selectedVehicleData?.featuresList?.map((feature, index) => (
                <div key={index}>
                  {" "}
                  <TextLine
                    label={feature?.addon}
                    value={`${feature?.quantity ?? 0} ${feature?.measurement}`}
                    styles={"font-semibold text-[14px] text-[#2B2B2B] "}
                    Linestyle={
                      index === selectedVehicleData?.featuresList?.length - 1
                        ? "w-0"
                        : ""
                    }
                  />{" "}
                </div>
              ))}
            <DriverContainer
              name={
                assignedDriver?.user?.firstName +
                " " +
                assignedDriver?.user?.lastName
              }
              driverImageUrl={assignedDriver?.user?.profilePicUrl}
              driverLastLocation={assignedDriver?.user?.lastLocation}
              coords={assignedDriver?.coords}
              pickupLocation={locationInput.pickup?.[0]?.location}
              setTimeToArrive={setTimeToArrive}
              mobile={assignedDriver?.user?.mobile}
            />
            <div className="mt-[100px]">
              {/* <div>
              <Button
                textcolor={"text-[#F15A2A]"}
                bordercolor={"border-[#F15A2A]"}
                label={"Track Status"}
                borderwidth={"border-[1px]"}
              />
            </div> */}
              <div>
                <Button
                  label="New Booking"
                  background={"bg-[#F15A2A]"}
                  textcolor={"text-white"}
                  onClick={resetBooking}
                />
              </div>
            </div>
          </div>
        </LoadScript>
      </div>
    </div>
  );
};

export default QuickBooking;
