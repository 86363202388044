import Homeicon from "../assets/images/home.png";
import Ambulance from "../assets/images/ambulanceicon.png";
import Analyticsicon from "../assets/images/Analytics.png";
import bookmarkicon from "../assets/images/bookmark.png";
import Drives from "../assets/icons/AdminNavbarIcons/Drivers.svg";
import Profile from "../assets/icons/AdminNavbarIcons/Profile.svg";
import Headset from "../assets/icons/AdminNavbarIcons/headset.svg";
import settings from "../assets/icons/AdminNavbarIcons/settings.svg";

export const userNavbarConsts = [
  {
    icon: Homeicon,
    title: "Home",
    route: "/dashboard",
  },
  {
    icon: Ambulance,
    title: "Trips",
    route: "/trips",
  },
  {
    icon: bookmarkicon,
    title: "Abondon Trips",
    route: "/abondontrips",
  },
  {
    icon: Analyticsicon,
    title: "Analytics",
    route: "/analyticspage",
  },
];

export const adminNavbarConsts = [
  {
    icon: Homeicon,
    title: "Home",
    route: "/dashboard",
  },
  {
    icon: Drives,
    title: "Drivers",
    route: "/drivers",
  },
  // {
  //     icon: Profile,
  //     title: "Registered Users",
  //     route: "/registered-users",
  // },
  {
    icon: Headset,
    title: "Agents",
    route: "/agents",
  },
  {
    icon: settings,
    title: "Services",
    route: "/services",
  },
  // {
  //   icon: Analyticsicon,
  //   title: "Analytics",
  //   route: "/analyticspage",
  // },
];

export const customStyles = {
  overlay: {
    backdropFilter: "blur(4px)",
    background: "rgba(0,0,0,0.45)",
  },
  content: {
    height: "100vh",
  },
};

export const customContentStyles = {
  content: {
    height: "100vh",
    background: "transparent",
    display: "flex",
    zIndex: "100",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    inset: "0px",
  },
};

export const customStyles1 = {
  content: {
    top: "75%",
    left: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
    borderRadius: "16px",
  },
};

export const customStyles3 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
    borderRadius: "16px",
    width: "95%",
    height: "90%",
    backgroundColor: "#fff",
  },
};

export const libraries = ["places"];

export const todayTripsHeaders = [
  {
    headerName: "Trip Id",
    headerId: "tripId",
    headerAlignment: "left",
    rowAlignment: "left",
  },
  {
    headerName: "Date of Trip",
    headerId: "timeOfBookingRequest",
    headerAlignment: "left",
    rowAlignment: "left",
  },
  // {
  //   headerName: "Type of Trip",
  //   headerId: "typeOfUser",
  //   headerAlignment: "left",
  //   rowAlignment: "left",
  // },
  {
    headerName: "Caller Name",
    headerId: "callerName",
    headerAlignment: "left",
    rowAlignment: "left",
  },
  {
    headerName: "Caller Number",
    headerId: "callerPhoneNumber",
    headerAlignment: "left",
    rowAlignment: "left",
  },
  {
    headerName: "Status",
    headerId: "status",
    headerAlignment: "left",
    rowAlignment: "left",
  },
  // {
  //   headerName: "From",
  //   headerId: "from",
  //   headerAlignment: "left",
  //   rowAlignment: "left",
  // },
  // {
  //   headerName: "To",
  //   headerId: "to",
  //   headerAlignment: "left",
  //   rowAlignment: "left",
  // },
  // {
  //   headerName: "Amount",
  //   headerId: "price",
  //   headerAlignment: "left",
  //   rowAlignment: "left",
  // },
  {
    headerName: "Actions",
    headerId: "actions",
    headerAlignment: "left",
    rowAlignment: "left",
  },
];

export const initialLocationValues = {
  pickup: null,
  drop: null,
};

export const abandonTripsHeaders = [
  // {
  //   headerName: "Trip Id",
  //   headerId: "tripId",
  //   headerAlignment: "left",
  //   rowAlignment: "left",
  // },
  {
    headerName: "Date of Trip",
    headerId: "dateandTimeofTrip",
    headerAlignment: "left",
    rowAlignment: "left",
  },
  // {
  //   headerName: "Type of Trip",
  //   headerId: "typeOfUser",
  //   headerAlignment: "left",
  //   rowAlignment: "left",
  // },
  {
    headerName: "Caller Name",
    headerId: "callerName",
    headerAlignment: "left",
    rowAlignment: "left",
  },
  {
    headerName: "Caller Number",
    headerId: "callerPhoneNumber",
    headerAlignment: "left",
    rowAlignment: "left",
  },
  {
    headerName: "Status",
    headerId: "status",
    headerAlignment: "left",
    rowAlignment: "left",
  },
  // {
  //   headerName: "From",
  //   headerId: "from",
  //   headerAlignment: "left",
  //   rowAlignment: "left",
  // },
  // {
  //   headerName: "To",
  //   headerId: "to",
  //   headerAlignment: "left",
  //   rowAlignment: "left",
  // },
  // {
  //   headerName: "Amount",
  //   headerId: "price",
  //   headerAlignment: "left",
  //   rowAlignment: "left",
  // },
  {
    headerName: "Actions",
    headerId: "actions",
    headerAlignment: "left",
    rowAlignment: "left",
  },
];

export const UNAVAILABLE = "UNAVAILABLE";
export const AVAILABLE = "AVAILABLE";
