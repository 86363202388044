export const APP_LOADING = "APP_LOADING";
export const DASHBOARD_ACTION_GET_NEW_DATA = "DASHBOARD_ACTION_GET_NEW_DATA";

//Login and Sign Up
export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const EMAIL_VERIFICATION_REQUEST = "EMAIL_VERIFICATION_REQUEST";
export const RESEND_VERIFICATION_REQUEST = "RESEND_VERIFICATION_REQUEST";
export const UPLOAD_ASSEST_REQUEST = "UPLOAD_ASSEST_REQUEST";

//Profile
export const READ_USER_BY_MOBILE = "READ_USER_BY_MOBILE";
export const READ_PROFILE_REQUEST = "READ_PROFILE_REQUEST";
export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_USER_TOKEN = "UPDATE_USER_TOKEN";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const SAVE_ADDRESS_REQUEST = "SAVE_ADDRESS_REQUEST";
export const UPDATE_ADDRESS_REQUEST = "UPDATE_ADDRESS_REQUEST";

//Region
export const SAVE_REGION_REQUEST = "SAVE_REGION_REQUEST";
export const SAVE_PINCODE_REGION_REQUEST = "SAVE_PINCODE_REGION_REQUEST";
export const READ_REGION_REQUEST = "READ_REGION_REQUEST";
export const DELETE_PINCODE_REGION_REQUEST = "DELETE_PINCODE_REGION_REQUEST";
export const UPDATE_REGION_REQUEST = "UPDATE_REGION_REQUEST";
export const DELETE_REGION_REQUEST = "DELETE_REGION_REQUEST";

//Addon
export const SAVE_ADDON_REQUEST = "SAVE_ADDON_REQUEST";
export const DELETE_ADDON_REQUEST = "DELETE_ADDON_REQUEST";
export const UPDATE_ADDON_REQUEST = "UPDATE_ADDON_REQUEST";
export const READ_ADDON_REQUEST = "READ_ADDON_REQUEST";

//Vehicle
export const SAVE_VEHICLE_REQUEST = "SAVE_VEHICLE_REQUEST";
export const SAVE_ADDON_VEHICLE_REQUEST = "SAVE_ADDON_VEHICLE_REQUEST";
export const SAVE_USER_VEHICLE_TYPE_REQUEST = "SAVE_USER_VEHICLE_TYPE_REQUEST";
export const DELETE_USER_VEHICLE_TYPE_REQUEST =
  "DELETE_USER_VEHICLE_TYPE_REQUEST";
export const READ_VEHICLE_REQUEST = "READ_VEHICLE_REQUEST";
export const DELETE_VEHICLE_REQUEST = "DELETE_VEHICLE_REQUEST";
export const DELETE_ADDON_VEHICLE_REQUEST = "DELETE_ADDON_VEHICLE_REQUEST";
export const UPDATE_VEHICLE_REQUEST = "UPDATE_VEHICLE_REQUEST";

//Tarrif
export const SAVE_TARIFF_REQUEST = "SAVE_TARIFF_REQUEST";
export const SAVE_ADDON_TARIFF_REQUEST = "SAVE_ADDON_TARIFF_REQUEST";
export const READ_TARIFF_REQUEST = "READ_TARIFF_REQUEST";
export const READ_TRIP_TARIFF_REQUEST = "READ_TRIP_TARIFF_REQUEST";
export const DELETE_TARIFF_REQUEST = "DELETE_TARIFF_REQUEST";
export const UPDATE_TARIFF_REQUEST = "UPDATE_TARIFF_REQUEST";
export const UPDATE_ADDON_TARIFF_REQUEST = "UPDATE_ADDON_TARIFF_REQUEST";

//Discount
export const SAVE_DISCOUNT_REQUEST = "SAVE_DISCOUNT_REQUEST";
export const READ_DISCOUNT_REQUEST = "READ_DISCOUNT_REQUEST";
export const DELETE_DISCOUNT_REQUEST = "DELETE_DISCOUNT_REQUEST";
export const UPDATE_DISCOUNT_REQUEST = "UPDATE_DISCOUNT_REQUEST";
export const DEACTIVATE_DISCOUNT_REQUEST = "DEACTIVATE_DISCOUNT_REQUEST";

//Driver
export const READ_DRIVER_REQUEST = "READ_DRIVER_REQUEST";
export const DEACTIVATE_DRIVER_REQUEST = "DEACTIVATE_DRIVER_REQUEST";
export const READ_DRIVERS_LOCATION_REQUEST = "READ_DRIVERS_LOCATION_REQUEST";
export const DELETE_DRIVER_REQUEST = "DELETE_DRIVER_REQUEST";

//Agent
export const READ_AGENT_REQUEST = "READ_AGENT_REQUEST";
export const DEACTIVATE_AGENT_REQUEST = "DEACTIVATE_AGENT_REQUEST";
export const SAVE_AGENT_REQUEST = "SAVE_AGENT_REQUEST";

//Users
export const READ_TYPE_USERS_REQUEST = "READ_TYPE_USERS_REQUEST";

//Maps
export const SAVE_LOCATIONS_POINTS = "SAVE_LOCATIONS_POINTS";
export const SAVE_SUGGESTIONS = "SAVE_SUGGESTIONS";

//Trip
export const SAVE_TRIP_REQUEST = "SAVE_TRIP_REQUEST";
export const UPDATE_TRIP_REQUEST = "UPDATE_TRIP_REQUEST";
export const READ_TRIP_REQUEST = "READ_TRIP_REQUEST";
export const READ_TODAY_TRIP_REQUEST = "READ_TODAY_TRIP_REQUEST";
export const READ_TRIP_ANALYTICS_REQUEST = "READ_TRIP_ANALYTICS_REQUEST";
export const SAVE_TRIP_ADDON_REQUEST = "SAVE_TRIP_ADDON_REQUEST";
export const UPDATE_TRIP_ADDON_REQUEST = "UPDATE_TRIP_ADDON_REQUEST";
export const SAVE_TRIP_DRIVER_REQUEST = "SAVE_TRIP_DRIVER_REQUEST";
export const CHECK_DRIVER_BUSY_REQUEST = "CHECK_DRIVER_BUSY_REQUEST";
export const UPDATE_TRIP_DRIVER_REQUEST = "UPDATE_TRIP_DRIVER_REQUEST";
export const SEARCH_ADDRESS_REQUEST = "SEARCH_ADDRESS_REQUEST";
