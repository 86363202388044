import * as actionTypes from "../ActionTypes";

//Regions
export const saveRegionAction = (payload) => ({
  type: actionTypes.SAVE_REGION_REQUEST,
  payload,
});

export const savePincodeRegionAction = (payload) => ({
  type: actionTypes.SAVE_PINCODE_REGION_REQUEST,
  payload,
});

export const readRegionAction = (payload) => ({
  type: actionTypes.READ_REGION_REQUEST,
  payload,
});

export const deletePincodeRegionAction = (payload) => ({
  type: actionTypes.DELETE_PINCODE_REGION_REQUEST,
  payload,
});

export const updateRegionAction = (payload) => ({
  type: actionTypes.UPDATE_REGION_REQUEST,
  payload,
});

export const deleteRegionAction = (payload) => ({
  type: actionTypes.DELETE_REGION_REQUEST,
  payload,
});

//Addons
export const saveAddonAction = (payload) => ({
  type: actionTypes.SAVE_ADDON_REQUEST,
  payload,
});

export const deleteAddonAction = (payload) => ({
  type: actionTypes.DELETE_ADDON_REQUEST,
  payload,
});

export const updateAddonAction = (payload) => ({
  type: actionTypes.UPDATE_ADDON_REQUEST,
  payload,
});

export const readAddonAction = (payload) => ({
  type: actionTypes.READ_ADDON_REQUEST,
  payload,
});

//Vehicle
export const saveVehicleAction = (payload) => ({
  type: actionTypes.SAVE_VEHICLE_REQUEST,
  payload,
});

export const saveAddonVehicleAction = (payload) => ({
  type: actionTypes.SAVE_ADDON_VEHICLE_REQUEST,
  payload,
});

export const saveUserVehicleTypeAction = (payload) => ({
  type: actionTypes.SAVE_USER_VEHICLE_TYPE_REQUEST,
  payload,
});

export const deleteUserVehicleTypeAction = (payload) => ({
  type: actionTypes.DELETE_USER_VEHICLE_TYPE_REQUEST,
  payload,
});

export const readVehicleAction = (payload) => ({
  type: actionTypes.READ_VEHICLE_REQUEST,
  payload,
});

export const deleteVehicleAction = (payload) => ({
  type: actionTypes.DELETE_VEHICLE_REQUEST,
  payload,
});

export const deleteAddonVehicleAction = (payload) => ({
  type: actionTypes.DELETE_ADDON_VEHICLE_REQUEST,
  payload,
});

export const updateVehicleAction = (payload) => ({
  type: actionTypes.UPDATE_VEHICLE_REQUEST,
  payload,
});

//Tarrif
export const saveTarrifAction = (payload) => ({
  type: actionTypes.SAVE_TARIFF_REQUEST,
  payload,
});

export const saveAddonTarrifAction = (payload) => ({
  type: actionTypes.SAVE_ADDON_TARIFF_REQUEST,
  payload,
});

export const readTarrifAction = (payload) => ({
  type: actionTypes.READ_TARIFF_REQUEST,
  payload,
});

export const readTripTarrifAction = (payload) => ({
  type: actionTypes.READ_TRIP_TARIFF_REQUEST,
  payload,
});

export const deleteTarrifAction = (payload) => ({
  type: actionTypes.DELETE_TARIFF_REQUEST,
  payload,
});

export const updateTarrifAction = (payload) => ({
  type: actionTypes.UPDATE_TARIFF_REQUEST,
  payload,
});

export const updateAddonTarrifAction = (payload) => ({
  type: actionTypes.UPDATE_ADDON_TARIFF_REQUEST,
  payload,
});

//Discount
export const saveDiscountAction = (payload) => ({
  type: actionTypes.SAVE_DISCOUNT_REQUEST,
  payload,
});

export const readDiscountAction = (payload) => ({
  type: actionTypes.READ_DISCOUNT_REQUEST,
  payload,
});

export const deleteDiscountAction = (payload) => ({
  type: actionTypes.DELETE_DISCOUNT_REQUEST,
  payload,
});

export const updateDiscountAction = (payload) => ({
  type: actionTypes.UPDATE_DISCOUNT_REQUEST,
  payload,
});

export const deactivateDiscountAction = (payload) => ({
  type: actionTypes.DEACTIVATE_DISCOUNT_REQUEST,
  payload,
});

export const readDriversLocationsAction = (payload) => ({
  type: actionTypes.READ_DRIVERS_LOCATION_REQUEST,
  payload,
});

export const readDriverAction = (payload) => ({
  type: actionTypes.READ_DRIVER_REQUEST,
  payload,
});

export const deactivateDriverAction = (payload) => ({
  type: actionTypes.DEACTIVATE_DRIVER_REQUEST,
  payload,
});

export const deleteDriverAction = (payload) => ({
  type: actionTypes.DELETE_DRIVER_REQUEST,
  payload,
});

// Agent
export const readAgentAction = (payload) => ({
  type: actionTypes.READ_AGENT_REQUEST,
  payload,
});

export const deactivateAgentAction = (payload) => ({
  type: actionTypes.DEACTIVATE_AGENT_REQUEST,
  payload,
});

export const saveAgentAction = (payload) => ({
  type: actionTypes.SAVE_AGENT_REQUEST,
  payload,
});

// QuickBooking

export const saveQuickBookingLocation = (payload) => ({
  type: actionTypes.SAVE_LOCATIONS_POINTS,
  payload,
});

export const saveQuickBookingLocationSuggestions = (payload) => ({
  type: actionTypes.SAVE_SUGGESTIONS,
  payload,
});

export const readTypeOfUsersAction = (payload) => ({
  type: actionTypes.READ_TYPE_USERS_REQUEST,
  payload,
});

export const saveTripAction = (payload) => ({
  type: actionTypes.SAVE_TRIP_REQUEST,
  payload,
});

export const updateTripAction = (payload) => ({
  type: actionTypes.UPDATE_TRIP_REQUEST,
  payload,
});

export const saveTripAddonAction = (payload) => ({
  type: actionTypes.SAVE_TRIP_ADDON_REQUEST,
  payload,
});

export const updateTripAddonAction = (payload) => ({
  type: actionTypes.UPDATE_TRIP_ADDON_REQUEST,
  payload,
});

export const saveTripDriverAction = (payload) => ({
  type: actionTypes.SAVE_TRIP_DRIVER_REQUEST,
  payload,
});

export const updateTripDriverAction = (payload) => ({
  type: actionTypes.UPDATE_TRIP_DRIVER_REQUEST,
  payload,
});

export const checkDriverBusyAction = (payload) => ({
  type: actionTypes.CHECK_DRIVER_BUSY_REQUEST,
  payload,
});

export const readTripAction = (payload) => ({
  type: actionTypes.READ_TRIP_REQUEST,
  payload,
});

export const readTodayTripAction = (payload) => ({
  type: actionTypes.READ_TODAY_TRIP_REQUEST,
  payload,
});

export const readTripAnalyticsAction = (payload) => ({
  type: actionTypes.READ_TRIP_ANALYTICS_REQUEST,
  payload,
});

export const searchAddressAction = (payload) => ({
  type: actionTypes.SEARCH_ADDRESS_REQUEST,
  payload,
});
