import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

//instance.defaults.headers.common['Content-Type'] = "application/json";
instance.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded";
// instance.defaults.headers.common['Access-Control-Allow-Origin'] = "*";

instance.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("token");
    if (token) {
      request.headers.setAuthorization("Bearer " + token);
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response?.data?.name === "TokenExpiredError" || response?.data?.type === 4) {
      window.location.pathname = "/logout";
      return;
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
